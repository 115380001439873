html,
body {
  overflow-x: hidden;
  height: auto;
}

.background {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.socials a {
  font-size: 28px;
  color: var(--verde-claro);
}

.links a {
  gap: 10px;
  color: var(--verde-claro);
  font-weight: bolder;
  text-decoration: none;
  &:hover{
    color: var(--verde-oscuro);
  }
}

.legal {
  font-size: 12px;
  margin: 0;
  color: var(--verde-claro);
  font-weight: bold;
}

.link{
  font-size: 12px;
  position: absolute;
  bottom: 15px;
  right: 15px;
  color: var(--verde-claro);
  font-weight: bolder;
}

.svg-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scaleY(3) scaleX(2.25);
  transform-origin: bottom;
  box-sizing: border-box;
  display: block;
  pointer-events: none;
}

footer {
  position: relative;
  left: 0;
  bottom: 0;
  display: flex;
  width: 100%;
  height: 370px;
}

section {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 30px;
  padding-bottom: 50px;
  padding-left: 60px;
  width: 100%;
}

@media (width > 420px) {
  section {
    align-items: center;
    padding-left: 0;
    gap: 20px;
  }

  .links {
    gap: 20px;
  }
}
@media (max-width: 572px){
  section{
    align-items: center;
    padding-left: 0;
    padding-top: 35px;
  }
  .links{
    flex-direction: column;
    text-align: center;
    gap: 5px;
  }
  footer{
    height: auto;
  }
  .link{
    bottom: 10px;
    font-size: 8px;
  }
}