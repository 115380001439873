#Nosotros{
  padding: 61px 0;
}
.nosotros-titulo{
  color: var(--verde-oscuro);
  font-weight: bolder;
  font-size: 42px;
}
.subtitulo{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nosotros-subtitulo{
  position: relative;
  z-index: 10;
  width: fit-content;
  background-color: white;
  padding: 10px 15px;
}
.nosotros-clippath{
  position: absolute;
  width: 100%;
  height: 5px;
  background-color: var(--verde-oscuro);
  z-index: 0;
}
@media (max-width:576px){
  .nosotros-titulo{
    font-size: 36px;
  }
  .nosotros-subtitulo{
    padding: 5px 10px;
  }
}