.card {
  background-color: var(--verde-fondo);
  text-align: center;
  color: var(--verde-oscuro);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  opacity: 1;
  transition: all 0.3s;
  cursor: default;
}
.planes-container:hover .card {
  opacity: 0.6;
  transform: scale(0.95);
}
.planes-container:hover .card:hover {
  opacity: 1;
  transform: none;
  transform: scale(1.05);
}
.card-title{
  font-weight: bolder;
  font-size: 32px;
}
.card-subtitle{
  font-weight: bolder;
  font-size: 22px;
}
.card p{
  font-weight: bold;
  font-size: 16px;
  margin: 0;
}
.card-button{
  font-size: 16px;
  background-color: var(--verde-oscuro);
  color: white;
  width: fit-content;
  padding: 10px 15px;
  border-radius: 10px;
  border: none;
  box-shadow: none;
  font-weight: bolder;

  &:hover{
    box-shadow: 0px 0px 5px 0px rgb(32, 102, 22)
  }
}
@media (max-width: 1024px){
  .planes-container:hover .card {
    opacity: 1;
    transform: none;
  }
  .planes-container:hover .card:hover {
    opacity: 1;
    transform: none;
  }
}
@media (max-width: 573px){
  .card-title{
    font-size: 24px;
  }
  .card-subtitle{
    font-size: 18px;
  }
}