.wtsp{
  position: fixed;
  bottom: 30px;
  right: 30px;
  font-size: 50px;
  cursor: pointer;
  z-index: 50;

  & a{
    color: var(--verde-oscuro);
  }

  &:hover{
    transform: scale(1.05);
  }
}

@media (max-width: 576px){
  .wtsp{
    bottom: 15px;
    right: 15px;
  }
}