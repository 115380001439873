#Planes{
  padding-top: 61px;
  padding-bottom: 61px;
}
.planes-titulo-cpntenedor{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
}
.planes-titulo{
  color: var(--verde-oscuro);
  font-weight: bolder;
  font-size: 42px;
  width: fit-content;
  background-color: white;
  z-index: 10;
  padding-right: 15px;
}
.planes-clippath{
  position: absolute;
  bottom: 15px;
  width: 100%;
  height: 5px;
  background-color: var(--verde-oscuro);
  z-index: 0;
}
.planes-container{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
@media (min-width:577px) and (max-width:1023px){
  .planes-container{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px 25px;
  }
}
@media (max-width:576px){
  .planes-titulo{
    font-size: 36px;
  }
  .planes-container{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
    margin: 0 25px;
  }
  .planes-clippath{
    right: -15px;
  }
}