.hero{
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.hero-video{
  position: absolute;
  top: 0;
  width: 150%;
  height: 100vh;
  object-fit: cover;
}
.hero-clippath{
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  background-color: black;
  opacity: 0.4;
}
.hero-logo{
  width: 450px;
  position: relative;
}
@media (min-width:577px) and (max-width:1024px){
  .hero-logo{
    width: 350px;
  }
}
@media (max-width:576px){
  .hero-logo{
    width: 300px;
  }
}