#Galeria{
  padding-top: 61px;
}
.galeria-titulo-cpntenedor{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
}
.galeria-titulo{
  color: var(--verde-oscuro);
  font-weight: bolder;
  font-size: 42px;
  width: fit-content;
  background-color: white;
  z-index: 10;
  padding-left: 15px;
}
.galeria-clippath{
  position: absolute;
  bottom: 15px;
  width: 100%;
  height: 5px;
  background-color: var(--verde-oscuro);
  z-index: 0;
}
@media (max-width:573px){
  .galeria-clippath{
    left: -15px;
  }
}
