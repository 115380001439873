/* #Servicios{
  padding-top: 61px;
} */
.servicios-contenedor{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  border-bottom: solid 5px var(--verde-oscuro);
}
.jardineria{
  background-image: url('../../Recursos/Imagenes/jardineriaServicio.jpeg');
}
.poda{
  background-image: url('../../Recursos/Imagenes/podaServicio.png');
}
.paisajismo{
  background-image: url('../../Recursos/Imagenes/WhatsApp\ Image\ 2024-06-10\ at\ 02.29.34.jpeg');

}
.fumigacion{
  background-image: url('../../Recursos/Imagenes/fumigacionServicio.webp');
}
.servicios-clippath{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--verde-fondo);
  opacity: 0.15;
}
.servicios-texto{
  font-size: 150px;
  font-weight: bolder;
  color: white;
  text-align: center;
  line-height: 130px;
  position: relative;
}
@media (min-width: 573px) and (max-width: 1024px){
  .servicios-texto{
    font-size: 100px;
    line-height: 80px;
  }
}

@media (max-width: 572px){
  .servicios-texto{
    font-size: 50px;
    line-height: 50px;
  }
  .servicios-contenedor{
    background-attachment: scroll;
  }
}